import React from 'react';
import styles from './PageDivider.module.scss';

interface Props {
  title: string;
}

const PageDivider: React.FC<Props> = ({ title }: Props) => {
  return (
    <div className={styles.container}>
      <span className={styles.header}>{title}</span>
      <div className={styles.delimiter} />
    </div>
  );
};

export default PageDivider;
