import React, { FC } from 'react';
import { Modal } from '../../../shared/modal/Modal';
import styles from './ThankPopUp.module.scss';
import useViewport from '../../../../hooks/useViewport';
import closeIconSloping from '../../../../images/icon-close-black.svg';
import cn from 'classnames';

interface Props {
  showPopup: boolean;
  setShowPopupToFalse: () => void;
}

const ThankPopUp: FC<Props> = ({ showPopup, setShowPopupToFalse }: Props) => {
  const { isMobile } = useViewport();
  return (
    <Modal
      isOpen={showPopup}
      handleClose={setShowPopupToFalse}
      hideClosebutton={isMobile}
      hideButtonWideView={!isMobile}
    >
      <div className={styles.popupContainer}>
        <div className={styles.crutchBlock}></div>
        <div className={styles.crutchCloseBtn}>
          <img src={closeIconSloping} alt="Close" style={{ width: '1.6rem' }} />
        </div>
        <p className={styles.popupContainer__thankTitle}>thank you for contacting us!</p>

        <p className={cn(styles.popupContainer__text, styles.hiddenMobile)}>
          {` We will do our best to respond to your message  within one to\n two business days.`}
        </p>

        <p className={cn(styles.popupContainer__text, styles.hiddenDesktop)}>
          {` We will do our best to respond to your message\n  within one to\n two business days.`}
        </p>

        {/* <button className={styles.popupContainer__nextButtons} onClick={setShowPopupToFalse}>
          next steps
        </button> */}
      </div>
    </Modal>
  );
};

export default ThankPopUp;
