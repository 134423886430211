import React from 'react';

const Map: React.FC = () => {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d623.5633242538967!2d-122.2446513331962!3d37.50003193350046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fa23fdcf9c0c9%3A0xe776ec82c48feab0!2s1480%20Industrial%20Rd%2C%20San%20Carlos%2C%20CA%2094070%2C%20USA!5e0!3m2!1sen!2sua!4v1599116790798!5m2!1sen!2sua"
      width="800"
      height="600"
      aria-hidden="false"
      frameBorder="0"
      tabIndex={0}
    ></iframe>
  );
};

export default Map;
