import React, { useState } from 'react';
import styles from './ScheduleForm.module.scss';
import ArrowButton from '../../shared/arrow-button/ArrowButton';
import { Formik, Field, Form } from 'formik';
import ThankPopUp from './thank-pop-up/ThankPopUp';
import FieldWithError from '../../shared/forms/field-error/FieldError';
import {
  validateFirstName,
  validateLastName,
  validateEmail,
  validatePhone,
} from '../../shared/forms/validators';
import classnames from 'classnames';

interface Values {
  visitType: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  date: string;
  time: string;
  text: string;
}

interface Props {
  onSubmit: (values: Values) => void;
}

const ScheduleForm: React.FC<Props> = ({ onSubmit }: Props) => {
  const [showPopup, setShowPopup] = useState(false);
  const togglePopUp = () => {
    setShowPopup(!showPopup);
  };

  //calculate min value for datepicker
  const date = new Date();
  const yyyy = date.getFullYear();
  let dd = date.getDate().toFixed();
  let mm = (date.getMonth() + 1).toFixed();
  if (date.getDate() < 10) {
    dd = '0' + dd;
  }
  if (date.getMonth() + 1 < 10) {
    mm = '0' + mm;
  }
  const today = yyyy.toFixed() + '-' + mm + '-' + dd;

  return (
    <section className={styles.container}>
      <div className={styles.dividerContainer}>
        <span className={styles.header}>type of visit</span>
        <div className={styles.delimiter} />
      </div>
      <Formik
        initialValues={{
          visitType: '',
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          date: '',
          time: '',
          text: '',
        }}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values);
          resetForm();
          togglePopUp();
        }}
      >
        {({ touched, errors }) => (
          <Form className={styles.formContainer}>
            <div className={styles.radioContainer}>
              <div className={styles.formRadioField}>
                <Field
                  className={styles.radioField}
                  type="radio"
                  id="inPerson"
                  name="visitType"
                  value="inPerson"
                />
                <label htmlFor="inPerson">
                  <div className={styles.dot} />
                </label>
                <span className={styles.formItemLabel}>In Person</span>
              </div>
              <div className={styles.formRadioField}>
                <Field
                  className={styles.radioField}
                  type="radio"
                  id="virtually"
                  name="visitType"
                  value="virtually"
                />
                <label htmlFor="virtually">
                  <div className={styles.dot} />
                </label>
                <span className={styles.formItemLabel}>Virtually</span>
              </div>
            </div>
            <div className={styles.dividerContainer}>
              <span className={styles.header}>contact information</span>
              <div className={styles.delimiter} />
            </div>
            <div className={styles.formRow}>
              <div
                className={classnames(
                  styles.formItem,
                  styles.wideItem,
                  touched.firstName && errors.firstName && styles.error,
                )}
              >
                <label className={styles.formItemLabel} htmlFor="firstName">
                  First name
                </label>
                <Field
                  className={styles.formItemField}
                  id="firstName"
                  name="firstName"
                  validate={validateFirstName}
                />
                <FieldWithError name="firstName" />
              </div>
              <div
                className={classnames(
                  styles.formItem,

                  touched.lastName && errors.lastName && styles.error,
                )}
              >
                <label className={styles.formItemLabel} htmlFor="lastName">
                  Last name
                </label>
                <Field
                  className={styles.formItemField + ' ' + styles.wide}
                  id="lastName"
                  name="lastName"
                  validate={validateLastName}
                />
                <FieldWithError name="lastName" />
              </div>
            </div>
            <div className={styles.formRow}>
              <div
                className={classnames(
                  styles.formItem,
                  styles.wideItem,
                  touched.phone && errors.phone && styles.error,
                )}
              >
                <label className={styles.formItemLabel} htmlFor="phone">
                  Phone
                </label>
                <Field
                  className={styles.formItemField}
                  id="phone"
                  name="phone"
                  validate={validatePhone}
                />
                <FieldWithError name="phone" />
              </div>
              <div
                className={classnames(
                  styles.formItem,

                  touched.email && errors.email && styles.error,
                )}
              >
                <label className={styles.formItemLabel} htmlFor="email">
                  Email
                </label>
                <Field
                  className={styles.formItemField + ' ' + styles.wide}
                  id="email"
                  name="email"
                  type="email"
                  validate={validateEmail}
                />
                <FieldWithError name="email" />
              </div>
            </div>
            <div className={styles.dividerContainer}>
              <span className={styles.header}>visit details</span>
              <div className={styles.delimiter} />
            </div>
            <div className={styles.formRow}>
              <div
                className={classnames(
                  styles.formItem,
                  styles.wideItem,
                  touched.date && errors.date && styles.error,
                )}
              >
                <label className={styles.formItemLabel} htmlFor="date">
                  Desired Date
                </label>
                <Field
                  className={styles.formItemField}
                  min={today}
                  id="date"
                  name="date"
                  type="date"
                  placeholder=""
                />
              </div>
              <div
                className={classnames(
                  styles.formItem,

                  touched.time && errors.time && styles.error,
                )}
              >
                <label className={styles.formItemLabel} htmlFor="time">
                  Desired Time
                </label>
                <Field
                  className={styles.formItemField + ' ' + styles.wide}
                  id="time"
                  name="time"
                  type="time"
                />
              </div>
            </div>
            <Field
              as="textarea"
              className={styles.formItemField + ' ' + styles.textField}
              id="text"
              name="text"
              type="textarea"
              placeholder="Tell us about your project"
              required
            />
            <button type="submit" className={styles.buttonContainer}>
              <ArrowButton
                name={'SUBMIT FORM'}
                fontSize={'1.6rem'}
                imgHeight={'2.5rem'}
                imgLeftMargin={'1.5rem'}
              />
            </button>
          </Form>
        )}
      </Formik>
      {showPopup && <ThankPopUp showPopup={showPopup} setShowPopupToFalse={togglePopUp} />}
    </section>
  );
};

export default ScheduleForm;
