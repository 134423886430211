import React from 'react';

import styles from './FindUs.module.scss';
import findUsPlan from '../../../images/findUs.jpg';
import Map from './Map';
import classnames from 'classnames';

interface Props {
  planImage?: string;
  phone?: string;
  email?: string;
  address?: string;
  hours?: { day: string; hour: string }[];
  holidays?: { title: string; days: string[] };
}

const FindUs: React.FC<Props> = ({ planImage, phone, address, email, holidays, hours }: Props) => {
  const months = Array.from({ length: 12 }, (e, i) => {
    return new Date(null, i + 1, null).toLocaleDateString('en', { month: 'long' });
  });

  const sortedHolidays: { name: string; date: number }[] = [];

  holidays?.days.forEach((d) => {
    const numberDate = d.match(/\d+/g)[0];
    months.forEach((m, i) => {
      if (d.includes(m)) {
        const totalDate = Date.parse(m + numberDate);
        sortedHolidays.push({
          name: d,
          date: totalDate,
        });
      }
    });
  });

  sortedHolidays.sort((a, b) => {
    return a.date - b.date;
  });

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.planBox}>
          <img className={styles.image} src={findUsPlan} alt="find us image" />
        </div>
        <div className={classnames(styles.mapContainer, 'hide-on-tablet')}>
          <Map></Map>
        </div>
      </div>
      <div className={styles.textContainer}>
        <div className={styles.textBox}>
          <div className={styles.subtitle}>DA VINCI MARBLE</div>
          <h1>{phone}</h1>
          <h1>{email}</h1>
          <h1 className={styles.address}>{address}</h1>
        </div>
        <div className={styles.textBox}>
          <div className={styles.subtitle}>HOURS</div>
          {hours?.map((s, i) => (
            <span key={i} className={styles.hours}>
              <h1 className={styles.greyText}>{s.day}</h1>
              <span>&nbsp;</span>
              <h1>{s.hour}</h1>
            </span>
          ))}
        </div>
        <div className={classnames(styles.mapContainer, 'hide-on-desktop')}>
          <Map></Map>
        </div>
        <div className={styles.holidaysBox}>
          <div className={classnames(styles.holidaysDivider, 'hide-on-desktop')} />
          <div className={styles.holidaysTitle}>{holidays?.title}</div>
          <div className={styles.plainText}>We are closed on the following dates:</div>
          <ul>
            {sortedHolidays?.map((s, i) => (
              <li key={s?.name + i}>
                <div className={styles.subText}>
                  {s?.name}
                  <span>&nbsp;</span>
                </div>
              </li>
            ))}
          </ul>
          <div className={classnames(styles.holidaysDivider, 'hide-on-desktop')} />
        </div>
      </div>
    </div>
  );
};

export default FindUs;
