import React from 'react';

import useViewport from '../../../hooks/useViewport';
import { Modal } from '../../shared/modal/Modal';
import AccountTile from '../../account/account-menu-tile/AccountMenuTile';
import styles from './InfoMenu.module.scss';
import { navigate } from 'gatsby';

interface MenuTile {
  title: string;
  path?: string;
  onClick?: () => void;
}

const InfoMenu: React.FC = () => {
  const { isTablet } = useViewport();
  const [isMenuModalOpen, setMenuModalState] = React.useState(false);

  const tiles: MenuTile[] = [
    {
      title: 'Schedule Your Visit',
      onClick: () => {
        navigate('#part1');
        toggleMenuModal();
      },
    },
    {
      title: 'What to Bring',
      onClick: () => {
        navigate('#part2');
        toggleMenuModal();
      },
    },
    {
      title: 'Find Us',
      onClick: () => {
        navigate('#part3');
        toggleMenuModal();
      },
    },
  ];

  const toggleMenuModal = () => {
    setMenuModalState(!isMenuModalOpen);
  };

  return (
    <div className={styles.container}>
      {isTablet && (
        <>
          <button className={styles.filter} onClick={toggleMenuModal}>
            MORE INFORMATION
          </button>
          <Modal isOpen={isMenuModalOpen} handleClose={toggleMenuModal}>
            <div className={styles.modal}>
              <span className={styles.accounts}>MORE INFORMATION</span>
              <div className={styles.divider} />
              {tiles.map((p, i) => (
                <div key={'tile_' + i}>
                  <AccountTile title={p.title} onClick={p.onClick} />
                  <div className={styles.divider} />
                </div>
              ))}
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default InfoMenu;
