import React from 'react';

import styles from './BringCard.module.scss';
import OffsetBorderContainer from '../../shared/offset-border-container/OffsetBorderContainer';
import checkBox from '../../../images/checkBox.jpg';
import useViewport from '../../../hooks/useViewport';

interface Props {
  image: string;
  title: string;
}

const BringCard: React.FC<Props> = ({ image, title }: Props) => {
  const { isMobile } = useViewport();
  return (
    <div className={styles.container}>
      <OffsetBorderContainer offset={isMobile ? '0.7rem' : ''}>
        <img className={styles.image} alt="what to bring" src={image} />
      </OffsetBorderContainer>
      <div className={styles.content}>
        <div className={styles.checkBox}>
          <img className={styles.icon} src={checkBox} alt="card img" />
        </div>
        <div className={styles.text}>{title}</div>
      </div>
    </div>
  );
};

export default BringCard;
