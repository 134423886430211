import axios from 'axios';
import { graphql } from 'gatsby';
import React from 'react';

import Layout from '../components/layout/layout';
import BringCard from '../components/schedule-visit/bring-card/BringCard';
import FindUs from '../components/schedule-visit/find-us/FindUs';
import InfoMenu from '../components/schedule-visit/info-menu/InfoMenu';
import PageDivider from '../components/schedule-visit/page-divider/PageDivider';
import ScheduleForm from '../components/schedule-visit/schedule-form/ScheduleForm';
import SEO from '../components/seo';
import Highlights from '../components/shared/highlights/Highlights';
import { StrapiImage } from '../components/shared/interfaces';
import { API_URL } from '../config';
import headerImg from '../images/plan-your-visit.png';
import styles from './schedule-visit.module.scss';

interface Props {
  data: {
    strapi: {
      scheduleVisitPage: {
        title: string;
        subtitle: string;
        phone: string;
        email: string;
        address: string;
        holidays_title: string;
      };
      scheduleVisitHours: Array<{
        day: string;
        hour: string;
      }>;
      scheduleVisitHolidays: Array<{
        day: string;
      }>;
      scheduleVisitBringCars: Array<{
        text: string;
        image: StrapiImage;
      }>;
    };
  };
}

const ScheduleVisit: React.FC<Props> = ({
  data: {
    strapi: {
      scheduleVisitPage: page,
      scheduleVisitBringCars: bringCards,
      scheduleVisitHolidays: holidays,
      scheduleVisitHours: hours,
    },
  },
}: Props) => {
  return (
    <Layout lightNavbar={true}>
      <SEO title={'Schedule your visit'} />
      <section className={styles.topImage}>
        <div className={styles.imageCover} />
        <img alt="welcome in" src={headerImg} />
      </section>
      <div className={styles.wrapper}>
        <InfoMenu />
        <section className={styles.highlights}>
          <Highlights
            subtitleWidth="30rem"
            title={page.title}
            subtitle={page.subtitle}
            description={''}
          />
        </section>
        <section className={styles.formSection} id={'part1'}>
          <ScheduleForm
            onSubmit={(values) => {
              axios.post(`${API_URL}/schedule-visit-requests`, values).catch(console.log);
            }}
          />
        </section>
        <section className={styles.bringSection}>
          <div className={styles.delimiter}>
            <PageDivider title={'WHAT TO BRING'} />
          </div>
          <div className={styles.cardsContainer} id={'part2'}>
            {bringCards.map((c, i) => {
              return <BringCard title={c.text} image={c.image.url} key={i} />;
            })}
          </div>
        </section>
        <section className={styles.findUsSection} id={'part3'}>
          <PageDivider title={'FIND US'} />
          <FindUs
            address={page.address}
            phone={page.phone}
            email={page.email}
            holidays={{ title: page.holidays_title, days: holidays.map((h) => h.day) }}
            hours={hours}
          />
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    strapi {
      scheduleVisitPage {
        title
        subtitle
        phone
        email
        address
        holidays_title
      }
      scheduleVisitHours {
        day
        hour
      }
      scheduleVisitHolidays {
        day
      }
      scheduleVisitBringCars {
        text
        image {
          url
        }
      }
    }
  }
`;

export default ScheduleVisit;
